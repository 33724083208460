'use client';

import React, { useMemo } from 'react';
import { useSearchParams } from 'next/navigation';
import type { LoginProps } from 'components/commercetools-ui/organisms/authentication/login';
import Login from 'components/commercetools-ui/organisms/authentication/login';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic/hooks';
import type { TasticProps } from 'frontastic/tastics/types';
import type { Reference } from 'types/reference';

export interface AccountLoginTasticProps extends LoginProps {
    accountLink: Reference;
}

const AccountLoginTastic = ({ data }: TasticProps<AccountLoginTasticProps>) => {
    const searchParams = useSearchParams();

    const lvp = searchParams.get('lvp');

    //redirection link after user is logged in
    const redirectLink = useMemo(() => {
        const lastVisitedPage = lvp ? `/${lvp}` : data.accountLink;
        return lastVisitedPage;
    }, [data.accountLink, lvp]);

    const { loggedIn } = useAccount();

    return loggedIn ? (
        <Redirect target={redirectLink} />
    ) : (
        <div className="bg-checkout-bg p-16 lg:pb-250 lg:pt-60">
            <div className="m-auto max-w-[530px] bg-white p-20 lg:p-30">
                <Login {...data} />
            </div>
        </div>
    );
};

export default AccountLoginTastic;
