import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useAccount } from 'frontastic';
import useBottomSectionProps from '../hooks/useBottomSectionProps';

type ThankYouFooterProps = {
    loading: boolean;
    orderId?: string;
};

const ThankYouFooter: FC<ThankYouFooterProps> = ({ loading, orderId }) => {
    const { loggedIn } = useAccount();

    const { bottomSectionProps } = useBottomSectionProps(loggedIn, orderId);

    return (
        <div className="grid border-b border-neutral-400 pb-24 pt-16 md:border-b-0 lg:pb-0 lg:pt-26">
            <Typography
                className="mb-16 w-fit font-heading text-18 leading-[20px] text-primary-black md:mb-24 md:leading-normal"
                asSkeleton={loading}
            >
                {bottomSectionProps?.title}
            </Typography>
            <Button className="w-full py-14 md:w-fit md:px-56" asSkeleton={loading} onClick={bottomSectionProps.onClick}>
                {bottomSectionProps?.cta}
            </Button>
        </div>
    );
};

export default ThankYouFooter;
