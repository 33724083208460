import React from 'react';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic';

const Register = () => {
    const { loggedIn } = useAccount();
    const redirectLink = loggedIn ? '/account' : '/login';

    return <Redirect target={redirectLink} />;
};

export default Register;
