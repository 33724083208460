'use client';

import type { FC } from 'react';
import React, { useEffect } from 'react';
import Script from 'next/script';
import { Partytown } from '@builder.io/partytown/react';
import { googleTagManagerForward } from '@builder.io/partytown/services';
import type { GoogleSettings } from '@wilm/shared-types/google/Settings';

export interface GASnippetType {
    googleSettings?: GoogleSettings;
}

declare global {
    interface Window {
        gtmDebugMode: boolean;
    }
}

const GASnippet: FC<GASnippetType> = ({ googleSettings }) => {
    const gaTrackingIdToUse = googleSettings?.gtmEnabled && googleSettings?.gtmTrackingId;
    const isDebugMode = googleSettings?.gtmDebugMode;
    const withPartytown = googleSettings?.gtmPartytownEnabled;

    useEffect(() => {
        if (gaTrackingIdToUse && isDebugMode) {
            window.gtmDebugMode = true;
        }
    }, []);

    if (!gaTrackingIdToUse) return null;

    if (withPartytown) {
        return (
            <>
                <Partytown debug={isDebugMode} forward={googleTagManagerForward} />

                <Script id="googletagmanager" strategy="afterInteractive" type="text/partytown">
                    {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${gaTrackingIdToUse}');
                `}
                </Script>
            </>
        );
    }

    return (
        <Script id="googletagmanager" strategy="afterInteractive">
            {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${gaTrackingIdToUse}');
                `}
        </Script>
    );
};
export default GASnippet;
