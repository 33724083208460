'use client';

import React from 'react';

import Register from 'components/commercetools-ui/organisms/authentication/register';

const AccountRegisterTastic = () => {
    return <Register />;
};

export default AccountRegisterTastic;
