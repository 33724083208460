'use client';

import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import type { Order } from '@wilm/shared-types/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import ThankYouOrderInfo from './components/order-info';
import ThankYouFooter from './components/thank-you-footer';
import ThankYouHeader from './components/thank-you-header';
import ThankYouLearners from './components/thank-you-learners';
import OrderSummary from '../order-summary';

export interface ThankYouProps {
    emailNotRecievedContent: string;
}

const ThankYouContent: FC<ThankYouProps> = ({ emailNotRecievedContent }) => {
    const { formatMessage } = useFormat({ name: 'thank-you' });

    const router = useRouter();
    const [order, setOrder] = useState<Order & { learnersLink: string }>();

    const removeLastPlacedOrder = () => {
        sessionStorage.removeItem('lastPlacedOrder');
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.localStorage.getItem('autoAddedLineItemId')) {
                window.localStorage.removeItem('autoAddedLineItemId');
            }

            const lastPlacedOrder = sessionStorage.getItem('lastPlacedOrder');
            if (!lastPlacedOrder) {
                router.replace('/');
            } else {
                setOrder(JSON.parse(lastPlacedOrder));
            }
            window.addEventListener('beforeunload', removeLastPlacedOrder);
        }
        return () => {
            window.removeEventListener('beforeunload', removeLastPlacedOrder);
        };
    }, []);

    if (!order)
        return (
            <div className="lg:gap-26 lg:bg-checkout-bg lg:px-40 lg:py-80">
                <div className="items-start lg:flex lg:gap-40">
                    <div className="bg-white px-16 md:px-24 lg:w-full lg:rounded-md lg:py-36">
                        <ThankYouHeader email={undefined} emailNotRecievedContent={emailNotRecievedContent} />
                        <ThankYouFooter loading={true} />
                    </div>

                    <OrderSummary
                        className="bg-white px-16 max-lg:w-full md:mt-24 lg:mt-0 lg:min-w-[30%] lg:p-40  lg:py-36"
                        title={formatMessage({ id: 'order?.details', defaultMessage: 'Order details' })}
                        dataReference="order"
                        includeSummaryAccordion
                        includeItemsList
                        isThanksPage={true}
                        classNames={{
                            totalAmount: 'md:text-18 font-medium flex items-center justify-between'
                        }}
                        asSkeleton={true}
                    />
                </div>
            </div>
        );

    return (
        <div className="lg:gap-26 lg:bg-checkout-bg lg:px-40 lg:py-80">
            <div className="items-start lg:flex lg:gap-40">
                <div className="bg-white px-16 md:px-24 lg:w-full lg:rounded-md lg:py-36">
                    <ThankYouHeader email={order?.email} emailNotRecievedContent={emailNotRecievedContent} />
                    <ThankYouOrderInfo
                        firstName={order?.billingAddress?.firstName}
                        lastName={order?.billingAddress?.lastName}
                        order={order}
                    />
                    <ThankYouLearners learnersLink={order?.learnersLink} />
                    <ThankYouFooter loading={!order?.sum} orderId={order?.orderId} />
                </div>

                <OrderSummary
                    className="bg-white px-16 max-lg:w-full md:mt-24 lg:mt-0 lg:min-w-[30%] lg:p-40  lg:py-36"
                    title={formatMessage({ id: 'order?.details', defaultMessage: 'Order details' })}
                    order={order}
                    dataReference="order"
                    includeSummaryAccordion
                    includeItemsList
                    isThanksPage={true}
                    classNames={{
                        totalAmount: 'md:text-18 font-medium flex items-center justify-between'
                    }}
                    asSkeleton={!order?.orderId}
                />
            </div>
        </div>
    );
};

export default ThankYouContent;
